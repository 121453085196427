<template>
  <div>
    <h2>Справочник контрагентов</h2>

    <contractors-table></contractors-table>
  </div>
</template>

<script>
import ContractorsTable from "@/components/ecologist/classifiers/contractors/contractorsTable";

export default {
  name: "contractorsMain",
  components: { ContractorsTable },
};
</script>

<style scoped></style>
