<template>
  <div>
    <contractors-main></contractors-main>
  </div>
</template>

<script>
import ContractorsMain from "@/components/ecologist/classifiers/contractors/contractorsMain";
export default {
  name: "classifiersContractorsMainView",
  components: { ContractorsMain },
};
</script>

<style scoped></style>
