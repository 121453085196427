<template>
  <div>
    <search-block title="контрагента">
      <el-row :gutter="10">
        <el-col :span="22">
          <el-input
            placeholder="Начните вводить наименование"
            v-model="search"
            @input="searchContractorsByName"
          ></el-input>
        </el-col>
        <el-col :span="2">
          <el-button @click="showAddModal" type="success">Добавить</el-button>
        </el-col>
      </el-row>
    </search-block>

    <el-table stripe border :data="listContractors.content">
      <el-table-column label="Наименование" prop="nameManual"></el-table-column>
      <el-table-column
        label="Местонахождение (юридический адрес)"
        prop="addressManual"
      ></el-table-column>
      <el-table-column
        label="Телефон, факс"
        prop="phoneNumberManual"
      ></el-table-column>
      <el-table-column
        label="Номер договора"
        prop="contractNumber"
      ></el-table-column>
      <el-table-column
        label="Дата подписания договора"
        prop="contractDateFrom"
      ></el-table-column>
      <el-table-column
        label="ФИО ответственного лица, телефон"
        prop="responsiblePersonNamePhone"
      ></el-table-column>
      <el-table-column width="150" label="">
        <template slot-scope="item">
          <el-row :gutter="10">
            <el-col :span="24">
              <el-button
                size="mini"
                style="width: 100%"
                @click="edit(item.row)"
                type="warning"
                >Редактировать</el-button
              >
              <el-button
                @click="deleteContractor(item.row)"
                size="mini"
                style="width: 100%; margin-left: 0; margin-top: 5px"
                type="danger"
                >Удалить</el-button
              >
            </el-col>
          </el-row>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      style="text-align: center"
      layout="total, sizes, prev, pager, next"
      background
      :page-sizes="[20, 40, 60, 80, 100]"
      :page-size="listContractors.size"
      :current-page.sync="listContractors.number + 1"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :total="listContractors.totalElements"
    >
    </el-pagination>

    <custom-modal
      title="Добавить контрагента"
      :visible="showModal === 'addContractors'"
    >
      <contractors-add></contractors-add>
    </custom-modal>

    <custom-modal
      title="Изменить контрагента"
      :visible="showModal === 'updateContractors'"
    >
      <contractors-edit
        :selected-contractors="selectedContractors"
      ></contractors-edit>
    </custom-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import SearchBlock from "@/components/searchBlock";
import CustomModal from "@/components/customModal";
import ContractorsAdd from "@/components/ecologist/classifiers/contractors/contractorsAdd";
import notification from "@/mixins/notification";
import ContractorsEdit from "@/components/ecologist/classifiers/contractors/contractorsEdit";

export default {
  name: "contractorsTable",
  mixins: [notification],
  components: { ContractorsEdit, ContractorsAdd, CustomModal, SearchBlock },
  data() {
    return {
      search: null,
      selectedContractors: null,
    };
  },

  computed: {
    ...mapGetters({
      listContractors: "GETLISTCONTRACTORS",
      showModal: "GETSHOWMODAL",
    }),
  },

  methods: {
    showAddModal() {
      this.$store.dispatch("setShowModal", "addContractors");
    },

    handleSizeChange(val) {
      this.getAllContractors({ page: 0, size: val });
    },

    handleCurrentChange(val) {
      this.getAllContractors({
        page: val - 1,
        size: this.listContractors.size,
      });
    },

    searchContractorsByName(query) {
      console.log(query);
      this.$store.dispatch("searchContractorsByName", {
        query: query,
        page: 0,
        size: 20,
      });
    },

    deleteContractor(item) {
      this.confirm(
        "Удалить контрагента",
        "Вы действительно хотите удалить контрагент",
        "warning",
        "Удалить",
        "Отмена"
      ).then(() => {
        this.$store
          .dispatch("deleteContractor", item.contractorId)
          .then((response) => {
            console.log(response.data);
            this.notification("Успешно", "Контрагент удален", "success");
            this.getAllContractors({
              page: this.listContractors.number,
              size: this.listContractors.size,
            });
          });
      });
    },

    edit(item) {
      this.selectedContractors = item;
      this.$store.dispatch("setShowModal", "updateContractors");
    },

    getAllContractors(data) {
      this.$store.dispatch("getAllContractors", data);
    },
  },

  mounted() {
    this.getAllContractors({ page: 0, size: 20 });
  },
};
</script>

<style scoped></style>
